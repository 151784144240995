body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.venue-Pagination >.ant-pagination-item a {color: #88C1C5;}
.venue-Pagination >.ant-pagination-item:hover {border-color: white;}
.venue-Pagination >.ant-pagination-item:hover a {color: white;}
.venue-Pagination > .ant-pagination-item-active {border-color: #D2CFF5;}
.venue-Pagination > .ant-pagination-item-active a {color: white;}
