.boack{
    position:absolute;
}
.notice{
    text-align: center;
    line-height:100px;
    margin:0 auto;
    font-size:20px;
}
.notice-dark{
    text-align: center;
    line-height:100px;
    margin:0 auto;
    color:#FFFFFF;
    font-size:20px;
}
