.header-size{
    margin-bottom:5px;
    padding:8px;
}
.header-zxyl-size{
    font-size:16px;
}
.header-date-icon-size{
    font-size: 20px;
    margin-left: 10px;
}
.header-date-text-size{
    font-size:16px;
    margin-left:5px;
}
.header-pagination-size{
    font-size: 14px;
}
.header-setting-size{
    font-size: 13px;
    padding: 2px 2px;
    border-radius: 3px;
}
.header-fullscreen-icon-size{
    font-size: 23px;
    margin-left: 5px;
}


.channel-name-size{
    font-size: 15px;
    margin-left: 3px;
    padding: 0;
}
.channel-name-large-size{
    font-size: 24px;
    margin-left: 3px;
    padding: 0;
}
.channel-sn-size{
    font-size:12px;
    margin-left:5px;
}
.channel-normal-size{
    font-size:15px;
    margin-left:3px;
}
.channel-abnormal-size{
    font-size: 12px;
    border-radius: 20px;
    padding: 2px 10px;
    text-align: center;
}

.channel-bpm-der-container-size{
    padding:5px 0;
    width:80px;
}
.channel-bpm-edr-size{
    font-size:16px;
    margin:2px 0;
}

.channel-bpm-container-size{
    width:70px;
}
.channel-bpm-size{
    font-size:32px;
}

.channel-bpm-edr-large-container-size{
    padding:5px 0;
    width:140px;
}

.channel-bpm-edr-large-size{
    font-size:18px;
    margin-left: 10px;
    height: 100%;
}
.channel-bpm-edr-value-large-size{
    font-size:35px;
    height: 100%;
}
.channel-bpm-large-container-size{
    padding:10px 0;
    width:80px;
}
.channel-bpm-large-size{
    font-size:18px;
    width: 100%;
}
.channel-bpm-value-large-size{
    font-size:40px;
    margin-top: 5px;
    width: 100%;
}
.channel-head-img-size{
    width:20px;
    height:20px;
    margin: -3px 3px 0 10px;
}
.channel-bpm-edr-img-size{
    margin:2px 10px;
    width: 18px;
    height: 18px;
}
.channel-bpm-img-size{
    width: 30px;
    height: 30px;
}

.channel-data-empty-size{
    line-height:50px;
    font-size:20px;
}

.spinner-size {
    width: 60px;
    height: 55px;
    font-size: 10px;
}
.spinner-size > div {
    height: 100%;
    width: 4px;
    margin-left: 2px;
}

.setting-row-size{
    height: 60px;
}

.setting-row-size2{
    height: 45px;
}

.setting-row-size2-slider{
    height: 60px;
}

.setting-select-size{
    font-size: 14px;
}

.v2-card-footer-container-size{
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    background-color: #8cc8ff;
}
@media (min-width: 1px) and (max-width: 600px) {
    .header-size{
        margin-bottom:3px;
        padding:4px 3px;
    }
    .header-zxyl-size{
        font-size:12px;
    }
    .header-date-icon-size{
        font-size: 12px;
        margin-left: 20px;
    }
    .header-date-text-size{
        font-size:12px;
        margin-left:5px;
    }
    .header-pagination-size{
        font-size: 12px;
    }
    .header-setting-size{
        font-size: 12px;
        padding: 2px 2px;
        border-radius: 3px;
    }
    .header-fullscreen-icon-size{
        font-size: 18px;
        margin-left: 2px;
    }


    .channel-name-size{
        font-size: 12px;
        margin-left: 3px;
        padding: 0;
    }
    .channel-sn-size{
        font-size:12px;
        margin-left:5px;
    }
    .channel-normal-size{
        font-size:12px;
        margin-left:3px;
    }

    .channel-name-large-size{
        font-size: 16px;
        margin-left: 3px;
        padding: 0;
    }

    .channel-abnormal-size{
        font-size: 12px;
        border-radius: 10px;
        padding: 1px 5px;
        text-align: center;
    }

    .channel-bpm-der-container-size{
        padding:5px 0;
        width:55px;
    }
    .channel-bpm-edr-size{
        font-size:14px;
        margin:2px 0;
    }

    .channel-bpm-container-size{
        width:50px;
    }
    .channel-bpm-size{
        font-size:20px;
    }

    .channel-bpm-edr-large-container-size{
        padding:2px 0;
        width:90px;
    }

    .channel-bpm-edr-large-size{
        font-size:12px;
        margin-left: 5px;
        height: 100%;
    }
    .channel-bpm-edr-value-large-size{
        font-size:22px;
        height: 100%;
    }
    .channel-bpm-large-container-size{
        padding:5px 0;
        width:50px;
    }
    .channel-bpm-large-size{
        font-size:12px;
        width: 100%;
    }
    .channel-bpm-value-large-size{
        font-size:24px;
        margin-top: 3px;
        width: 100%;
    }
    .channel-head-img-size{
        width:15px;
        height:15px;
        margin: -3px 2px 0 5px;
    }
    .channel-bpm-edr-img-size{
        margin:2px 8px;
        width: 12px;
        height: 12px;
    }
    .channel-bpm-img-size{
        width: 18px;
        height: 18px;
    }

    .channel-data-empty-size{
        line-height:35px;
        font-size:15px;
    }

    .spinner-size {
        width: 60px;
        height: 35px;
        font-size: 10px;
    }
    .spinner-size > div {
        height: 100%;
        width: 2px;
        margin-left: 2px;
    }
    .setting-row-size{
        height: 40px;
    }
    .setting-row-size2{
        margin-top: 10px;
        height: 70px;
        /*background-color: #8cc8ff;*/
    }
    .setting-row-size2-slider{
        margin-top: 10px;
        height: 85px;
    }
    .setting-select-size{
        font-size: 12px;
    }
}
@media (min-width: 600px) and (max-width: 1250px){
    .header-size{
        margin-bottom:3px;
        padding:4px 3px;
    }
    .header-zxyl-size{
        font-size:12px;
    }
    .header-date-icon-size{
        font-size: 12px;
        margin-left: 20px;
    }
    .header-date-text-size{
        font-size:12px;
        margin-left:5px;
    }
    .header-pagination-size{
        font-size: 12px;
    }
    .header-setting-size{
        font-size: 12px;
        padding: 2px 2px;
        border-radius: 3px;
    }
    .header-fullscreen-icon-size{
        font-size: 18px;
        margin-left: 2px;
    }


    .channel-name-size{
        font-size: 12px;
        margin-left: 3px;
        padding: 0;
    }
    .channel-sn-size{
        font-size:12px;
        margin-left:5px;
    }
    .channel-normal-size{
        font-size:12px;
        margin-left:3px;
    }

    .channel-name-large-size{
        font-size: 16px;
        margin-left: 3px;
        padding: 0;
    }

    .channel-abnormal-size{
        font-size: 12px;
        border-radius: 10px;
        padding: 1px 5px;
        text-align: center;
    }

    .channel-bpm-der-container-size{
        padding:5px 0;
        width:55px;
    }
    .channel-bpm-edr-size{
        font-size:14px;
        margin:2px 0;
    }

    .channel-bpm-container-size{
        width:50px;
    }
    .channel-bpm-size{
        font-size:20px;
    }

    .channel-bpm-edr-large-container-size{
        padding:2px 0;
        width:90px;
    }

    .channel-bpm-edr-large-size{
        font-size:12px;
        margin-left: 5px;
        height: 100%;
    }
    .channel-bpm-edr-value-large-size{
        font-size:22px;
        height: 100%;
    }
    .channel-bpm-large-container-size{
        padding:5px 0;
        width:50px;
    }
    .channel-bpm-large-size{
        font-size:12px;
        width: 100%;
    }
    .channel-bpm-value-large-size{
        font-size:24px;
        margin-top: 3px;
        width: 100%;
    }
    .channel-head-img-size{
        width:15px;
        height:15px;
        margin: -3px 2px 0 5px;
    }
    .channel-bpm-edr-img-size{
        margin:2px 8px;
        width: 12px;
        height: 12px;
    }
    .channel-bpm-img-size{
        width: 18px;
        height: 18px;
    }

    .channel-data-empty-size{
        line-height:35px;
        font-size:15px;
    }

    .spinner-size {
        width: 60px;
        height: 35px;
        font-size: 10px;
    }
    .spinner-size > div {
        height: 100%;
        width: 2px;
        margin-left: 2px;
    }
    .setting-row-size{
        height: 40px;
    }
    .setting-row-size2{
        height: 30px;
    }
    .setting-row-size2-slider{
        height: 45px;
    }
    .setting-select-size{
        font-size: 12px;
    }
}
@media (min-width: 1251px) and (max-width: 1700px){
    .header-size{
        margin-bottom:3px;
        padding:4px 3px;
    }
    .header-zxyl-size{
        font-size:15px;
    }
    .header-date-icon-size{
        font-size: 15px;
        margin-left: 20px;
    }
    .header-date-text-size{
        font-size:15px;
        margin-left:5px;
    }
    .header-pagination-size{
        font-size: 15px;
    }
    .header-setting-size{
        font-size: 15px;
        padding: 2px 2px;
        border-radius: 3px;
    }
    .header-fullscreen-icon-size{
        font-size: 24px;
        margin-left: 2px;
    }

    .channel-name-size{
        font-size: 15px;
        margin-left: 3px;
        padding: 0;
    }
    .channel-sn-size{
        font-size:12px;
        margin-left:5px;
    }
    .channel-normal-size{
        font-size:15px;
        margin-left:3px;
    }

    .channel-name-large-size{
        font-size: 19px;
        margin-left: 3px;
        padding: 0;
    }

    .channel-abnormal-size{
        font-size: 12px;
        border-radius: 10px;
        padding: 1px 5px;
        text-align: center;
    }

    .channel-bpm-der-container-size{
        padding:5px 0;
        width:70px;
    }
    .channel-bpm-edr-size{
        font-size:20px;
        margin:2px 0;
    }

    .channel-bpm-container-size{
        width:60px;
    }
    .channel-bpm-size{
        font-size:26px;
    }

    .channel-bpm-edr-large-container-size{
        padding:2px 0;
        width:100px;
    }

    .channel-bpm-edr-large-size{
        font-size:14px;
        margin-left: 8px;
        height: 100%;
    }
    .channel-bpm-edr-value-large-size{
        font-size:24px;
        height: 100%;
    }
    .channel-bpm-large-container-size{
        padding:5px 0;
        width:60px;
    }
    .channel-bpm-large-size{
        font-size:14px;
        width: 100%;
    }
    .channel-bpm-value-large-size{
        font-size:28px;
        margin-top: 5px;
        width: 100%;
    }
    .channel-head-img-size{
        width:17px;
        height:17px;
        margin: -3px 2px 0 5px;
    }
    .channel-bpm-edr-img-size{
        margin:2px 8px;
        width: 14px;
        height: 14px;
    }
    .channel-bpm-img-size{
        width: 22px;
        height: 22px;
    }

    .channel-data-empty-size{
        line-height:35px;
        font-size:15px;
    }

    .spinner-size {
        width: 60px;
        height: 45px;
        font-size: 10px;
    }
    .spinner-size > div {
        height: 100%;
        width: 3px;
        margin-left: 2px;
    }
    .setting-row-size{
        height: 50px;
    }
    .setting-row-size2{
        height: 40px;
    }
    .setting-row-size2-slider{
        height: 55px;
    }
    .setting-select-size{
        font-size: 12px;
    }
}

.channel-bpm-edr{
    float: right;
    text-align: center;
    font-weight: bolder;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.spinner{
    text-align: center;
}

.spinner > div {
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% {
           transform: scaleY(1.0);
           -webkit-transform: scaleY(1.0);
       }
}

.flicker{
    animation: fade 2s ease infinite;
}
@keyframes fade {
    from {
        /*opacity: 1.0;*/
        background-color: rgba(255,77,79,1);
        box-shadow: 0 0 10px 2px rgba(255,77,79,0.8)
    }
    50% {
        /*opacity: 0.5;*/
        background-color: rgba(255,77,79,.5);
        box-shadow: 0 0 10px 2px rgba(255,77,79,.3)
    }
    to {
        /*opacity: 1.0;*/
        background-color: rgba(255,77,79,1);
        box-shadow: 0 0 10px 2px rgba(255,77,79,0.8)
    }
}

.content-center{
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.channel-patienter-name{
    cursor: pointer;
}
