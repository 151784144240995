.menu-right {
    background-color: #273142;
    border: 2px solid #484F5F;
    padding-bottom: 8px;
    border-radius: 5px;
}

.menu-right > [role="menuitem"] {
    cursor: default;
    padding: 3px 10px 3px 25px;
    margin-top: 8px;
}

.menu-right > [role="menuitem"]:hover{
    background-color: #1890FF;
    color: #FFFFFF;
}
